import {useRouter} from "next/router";
import FooterColumn from "./FooterColumn";
import Link from 'next/link';

const Footer = () => {
  const router = useRouter();
  return (
    <div className="footer-wrapper">
    <div className="grid grid-cols-3 gap-10 items-start py-7 sm:py-12 md:py-16 lg:py-24 xl:py-32 px-7 sm:px-12 md:px-16 lg:px-24 xl:px-32 w-full bg-primary-600 text-neutral-200 text-2xs sm:text-xs lg:text-sm xl:text-md">
      <div className="col-span-1">
        <Link href="/">
          <img className="w-32 sm:w-24 md:w-32 lg:w-48 xl:w-64 2xl:w-72 object-contain" src="/images/logo1.png" alt=""/>
        </Link>
        <div className="mt-4">
          <FooterColumn options={[
            {text: ''},
            {text: 'DEXCORE UG'},
            {text: 'Berliner Str. 165'},
            {text: '10715 Berlin'}
          ]}/>
        </div>
      </div>
      <div className="col-span-1">
        <FooterColumn options={[
          {text: ''},
          {text: ''},
          {text: ''},
          {text: ''},
          {text: ''},
          {text: ''},
          {text: ''},
          {text: ''},
          {text: 'Kontakt', onClick: () => router.push('/contact/')},
          {text: ''},
          {text: 'Tel.: 030/ 60 59 02 47'},
          {text: 'Mail: info@dexcore.de'},
          {text: 'Web: www.dexcore.de'}
        ]}/>
      </div>
      <div className="col-span-1">
        <FooterColumn options={[
          {text: ''},
          {text: ''},
          {text: ''},
          {text: ''},
          {text: ''},
          {text: ''},
          {text: ''},
          {text: ''},
          {text: 'Datenschutz', onClick: () => router.push('/privacy/')},
          {text: ''},
          {text: 'Impressum', onClick: () => router.push('/data/')},
          {text: ''},
          {text: ''},
          {text: ''}
        ]}/>
      </div>
    </div>
  </div>

    );
    };


export default Footer;

interface IFooterColumn {
  options: FooterColumnOption[];
}

export interface FooterColumnOption {
  text: string;
  onClick?: () => void;
}

const FooterColumn = (props: IFooterColumn) => {
  const getOptions = () => props.options.map(option => (
    <p
      className={option.onClick && 'hover:text-amber-300 cursor-pointer'}
      key={option.text}
      onClick={option.onClick}
    >{option.text}</p>
  ));

  return (
    <div className="flex flex-col gap-1 sm:gap-2 md:gap-3">
      {getOptions()}
    </div>
  );
};

export default FooterColumn;

import {useRouter} from "next/router";
import Dropdown from "./Dropdown";
import Link from 'next/link';

interface Page {
  text: string;
  onClick: () => {};
}

const Header = () => {
  const router = useRouter();

  const services: Page[] = [
    {text: 'Badsanierung', onClick: () => openPage('/services/badsanierung')},
    {text: 'Innenausbau', onClick: () => openPage('/services/innenausbau')},
    {text: 'Wand- und Bodenbeläge', onClick: () => openPage('/services/Wand und Bodenbeläge'.toLowerCase().replace(/\s+/g, ''))},
    {text: 'Sanierung', onClick: () => openPage('/services/sanierung')},
    {text: 'Außenanlagen', onClick: () => openPage('/services/außenanlagen')},
  ];

  const pages1: Page[] = [
    {text: 'Home', onClick: () => openPage('/')},
    {text: 'Über uns', onClick: () => openPage('/about')}
  ];

  const pages2: Page[] = [
    {text: 'Partner', onClick: () => openPage('/partners')},
    {text: 'Karriere', onClick: () => openPage('/jobs')},
    {text: 'Referenzen', onClick: () => openPage('/credentials')},
    {text: 'Kontakt', onClick: () => openPage('/contact')}
  ];

  const getPages = (pages: Page[]) => pages.map(page => (
    <a
      className="cursor-pointer hover:text-amber-300"
      key={page.text}
      onClick={page.onClick}
    >{page.text}</a>
  ));

  const openPage = (url: string) => router.push(url);

  return (
    <div>
      <div className="grid sm:hidden gap-2 p-3 bg-primary-600 w-full px-7">
        <div className="flex justify-center">
          <Link href="/">
              <img
                className="h-7 w-auto"
                src="/images/logo.png"
                alt="logo"
              />
          </Link>
        </div>
         <div className="flex items-center w-full justify-between gap-2 text-neutral-200 text-2xs">
          {getPages(pages1)}
          <Dropdown text="Leistungen" options={services}/>
          {getPages(pages2)}
        </div>
      </div>
      <div className="hidden sm:visible sm:flex items-center w-full justify-between sm:justify-start py-2 sm:py-4 md:py-5 lg:py-7 xl:py-8 sm:px-12 md:px-16 lg:px-24 xl:px-32 bg-primary-600 gap-2 sm:gap-4 md:gap-5 lg:gap-10 xl:gap-16 text-neutral-200 text-2xs sm:text-xs md:text-sm lg:text-md xl:text-lg">
        <Link  href="/">

        <img
          className="mr-auto h-7 md:h-8 lg:h-10 w-auto"
          src="/images/logo1.png"
          alt="logo"
        />
        </Link>
        {getPages(pages1)}
        <Dropdown text="Leistungen" options={services}/>
        {getPages(pages2)}
      </div>
    </div>
  );
};

export default Header;

import Header from "./Header";
import Footer from "./Footer";

interface ILayout {
  children: any;
}

const Layout = (props: ILayout) => {
  return (
    <div className="grid w-full">
      <Header/>
      {props.children}
      <Footer/>
    </div>
  );
};

export default Layout;

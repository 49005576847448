import { useState, useEffect, useRef } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";

interface IDropdown {
  text: string;
  options: any[];
}

const Dropdown = (props: IDropdown) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !(event.target as Element).closest(".menu-button") // Cast event.target to Element
      ) {
        setIsOpen(false);
      }
    }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handleOptionClick = () => {
    setIsOpen(false); // Close the dropdown when an option is clicked
  };

  const getOptions = () =>
    props.options.map((option) => (
      <div className="px-1 py-1" key={option.text}>
        <Menu.Item>
          {({ active }) => (
            <button
              className={`${
                active && "bg-amber-300"
              } group flex rounded-md items-center w-full px-1 sm:px-2 py-1 sm:py-2 text-2xs sm:text-xs md:text-sm text-gray-900`}
              onClick={() => {
                option.onClick();
                handleOptionClick(); // Close the dropdown when an option is clicked
              }}
            >
              {option.text}
            </button>
          )}
        </Menu.Item>
      </div>
    ));

  return (
    <Menu as="div" className="relative inline-block text-left" ref={dropdownRef}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="relative"
      >
        <Menu.Button className="hover:text-amber-300 menu-button">{props.text}</Menu.Button>

        <Transition
          show={isOpen}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="z-50 absolute right-0 w-32 sm:w-48 md:w-56 mt-2 origin-top-right bg-neutral-200 divide-y divide-neutral-300 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            {getOptions()}
          </Menu.Items>
        </Transition>
      </div>
    </Menu>
  );
};

export default Dropdown;
